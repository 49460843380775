@include media-breakpoint-up(sm) {
  .subscribe {
    p {
      label {
        width: 15%;
      }
    }

    form {
      input {
        width: 30%;
      }
    }

    .button {
      width: auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  form {
    label {
      display: block;
      width: 100%;
    }

    input {
      display: block;
      width: 100%;
    }
  }

  .wide-label {
    width: 100% !important;
  }

  .button {
    width: auto;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .count {
    margin-left: .75rem;
  }

  .fa-comment-o {
    float: right;
    margin-right: 1.5rem;
  }

  .jumbotron {
    padding: 4rem 2rem;
    padding-bottom: 2rem;
  }
}
