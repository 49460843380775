.header {
  margin: 0;
  padding: 2em 1.5em 2.2em;

  @media screen and (min-width: $portrait-width) {
    padding-top: 4em;
  }

  background-color: #515865;
}

.header-container {
  max-width: 59em;
  margin: 0px auto;

  .heading {
    color: #EEEEEE;
    margin: 0;
  }
}

.nav {
  color: #707070;
  text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif;
    display:inline;
}

.nav li {
  padding-right: 1.5em;

  @media screen and (max-width: $iphone6plus-width) {
    padding-right: 1em;
  }
  display: inline-block;
  line-height: 1;
}

li {
  margin-left: 0;
  line-height: 1.7em;
}

.nav a {
  color: #959BA6;
}

.nav a:link {
  opacity: 1;
  transition: all 0.15s linear 0s;
  text-decoration: none;
}

.nav li a:visited {
  opacity: 1;
  transition: all 0.15s linear 0s;
  text-decoration: none;
}

.nav li a:hover {
  opacity: 0.6;
  cursor: pointer;
  text-decoration: none;
}

.description {
  color: #EEE;
  font-size: 0.75em;
  line-height: 1.5em;

  @media screen and (min-width: $portrait-width) {
    margin-top: 0.2em;
  }
}

.social-links {
  padding-top: 0.6em;
  margin-left: 0.2em;
  -webkit-padding-start: 0;
}

.social-links li {
    display: inline-block;
    padding-right: 10px;
    line-height: 0;
    font-size: 1.6em;

    &.github, &.twitter {
      font-size: 1.7em;
    }
}

.social-links a {
    color: #959BA6;
}

.social-links a:active {
	opacity: 0.6;
}

.social-links a:hover {
	opacity: 0.6;
}
