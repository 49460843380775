$black: #000;
$boston-university-red: #c90000;
$red: #ff0000;
$green: #48a947;
$grey: #8e8a94;
$light-grey: #f5f5f5;
$medium-grey: #e8e8e8;
$dark-grey: #c0c0c0;
$white: #fff;
$banner: #b94d4f;

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

.container {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.button {
  appearance: none;
  border: 0;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  padding: .6em;
}

.green {
  background-color: $green;
  color: $white;
}

a {
  &:hover {
    color: $boston-university-red;
    text-decoration: underline;
  }

  &:visited {
    color: $boston-university-red;
  }
  color: $boston-university-red;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.navbar-nav {
  display: inline-block;
  text-align: right;
  width: 100%;

  li {
    display: inline-block;
    font-size: 1.25rem;
    margin-left: .5rem;
    text-align: right;

    a {
      color: $medium-grey;
    }
  }
}

.bg-inverse {
  background: $black;
}

.jumbotron {
  padding-bottom: 2rem;
  text-align: center;
}

.about-me {
  margin-top: 4rem;
}

.errors {
  color: $boston-university-red;
}

.validation-error {
  border: 1px solid $boston-university-red;
}

#footer {
  background: #000;
  bottom: 0;
  left: 0;
  padding: 1rem;
  position: fixed;
  text-align: right;
  width: 100%;
  z-index: 1030;

  a {
    color: #e8e8e8;
    margin-right: 1rem;
    margin-top: 1rem;
  }
}
