.post {
  h3 {
    margin-bottom: 0;
    margin-top: 1.5rem;
  }

  p {
    color: $black;
    margin-bottom: .5rem;
    margin-top: 0;
  }

  .description {
    color: $black;
  }

}

p {
  margin-bottom: 0;
  margin-top: 1rem;

  &.no-margin {
    margin-top: 0;
  }
}

h5 {
  margin-top: 1.5rem;
}

.post-heading {
  border-bottom: 1px solid $black;

  margin-bottom: 2rem;
  margin-top: 3.5rem;

  .heading-icons {
    width: 100%;
  }

  h2 {
    display: inline-block;
    text-align: left;
    width: 79%;
  }

  p {
    margin-bottom: 0;

    &.icons {
      display: inline-block;
      font-size: 1.2rem;
      text-align: right;
      width: 19%;

      a {
        color: $black;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .post-heading {
    h2 {
      display: block;
      width: 100%;
    }

    p.icons {
      display: block;
      margin-top: 0;
      text-align: left;
      width: 100%;
    }
  }
}

.other-posts {
  margin-top: 2rem;

  p {
    margin-top: .5rem;
  }
}

.find-me {
  a {
    color: $black;
  }

  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: right;
  width: 99%;
}

.posts {
  a:hover {
    text-decoration: none;
  }

  a:hover > .post > h3, a:hover > .post > .more {
    text-decoration: underline;
  }

}

.comments {
  margin-top: 3rem;
}
